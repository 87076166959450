import { useEffect, useState } from "react";
import { Node, NodeTable } from "../../model/database";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";

export function useAggregateWarning(
    node: Node | undefined,
    disabled?: boolean,
): Result<boolean> {
    const nodeTable = useDatabase().nodes;
    const nodeRevision = useUpdateRevision(nodeTable);
    const [result, setResult] = useState<Result<boolean>>({});

    useEffect(() => {
        const ac = new AbortController();
        if (!disabled) {
            getAggregatedChildNodesWarning(node?.id ?? "", nodeTable)
                .then((warning: boolean) => {
                    if (!ac.signal.aborted) {
                        setResult({
                            data: (warning || node?.isWarning) ?? false,
                        });
                    }
                })
                .catch((error: unknown) => {
                    if (!ac.signal.aborted) {
                        setResult({ error });
                    }
                });
        }

        return () => {
            ac.abort();
        };
    }, [disabled, node, nodeRevision, nodeTable]);

    return result;
}

async function getAggregatedChildNodesWarning(
    id: string,
    nodeTable: NodeTable,
): Promise<boolean> {
    const childNodes: readonly Node[] = await nodeTable.findGreedily({
        index: "listNodesByParentId",
        parentId: id,
    });

    let aggregatedWarning = childNodes.some((node) => node.isWarning);

    await Promise.all(
        childNodes.map(async (child) => {
            const warning = await getAggregatedChildNodesWarning(
                child.id,
                nodeTable,
            );
            aggregatedWarning ||= warning;
        }),
    );

    return aggregatedWarning;
}

import API, { graphqlOperation } from "@aws-amplify/api-graphql";
import { useEffect } from "react";
import Observable, { ZenObservable } from "zen-observable-ts";
import {
    SubscribeError,
    SubscribeEventData,
} from "../../model/database/lib/graphql";
import { UpdateEvent } from "../../model/database/lib/update-event";

export function useSubscription<
    SOutput extends Record<string, { readonly id: string } | null | undefined>,
    SInput extends Record<string, unknown>,
>(table: EventTarget, query: string, variables?: SInput): void {
    useEffect(() => {
        let subscription: ZenObservable.Subscription;
        if (variables) {
            subscription = (
                API.graphql(graphqlOperation(query, variables)) as Observable<
                    SubscribeEventData<SOutput>
                >
            ).subscribe(
                (data) => {
                    const ids = new Set(
                        Object.values(data.value.data)
                            .map((d) => d?.id)
                            .filter(
                                (d): d is Exclude<string, undefined> =>
                                    d !== undefined,
                            ),
                    );
                    for (const id of ids) {
                        table.dispatchEvent(new UpdateEvent("update", id));
                    }
                },
                (error: SubscribeError) => {
                    console.error(error.error);
                },
            );
        }
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [query, table, variables]);
}

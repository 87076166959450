import { Storage } from "@aws-amplify/storage";
import { ValueType } from "../API";
import { Datum, DatumType, Node, NodeType } from "../model/database";
import { images } from "../view/components/common/node-card";

const CameraTypes: readonly string[] = [
    "835c3c5a-0f35-40c1-97a4-8cbd00be2ee2",
    "numazu-pg-node-type-10",
    "numazu-pg-node-type-11",
    "numazu-pg-node-type-12",
    "numazu-pg-node-type-13",
    "numazu-pg-node-type-20",
    "numazu-pg-node-type-21",
    "numazu-pg-node-type-22",
    "numazu-pg-node-type-23",
    "numazu-pg-node-type-38",
    "numazu-pg-node-type-39",
    "numazu-pg-node-type-53",
    "numazu-pg-node-type-80",
    "numazu-pg-node-type-81",
    "numazu-pg-node-type-82",
    "numazu-pg-node-type-83",
    "numazu-pg-node-type-84",
    "numazu-pg-node-type-85",
    "VIVOTEK-FD9368-HTV",
    "Panasonic-BB-SC384B",
];

/**
 * NodeCardビジネスロジッククラス
 */
/* istanbul ignore next */
export class NodeCardRule {
    /**
     * Node
     */
    private node: Node | undefined = undefined;

    /**
     * NodeType
     */
    private nodeType: NodeType | undefined = undefined;

    /**
     * DatumTypes
     */
    private datumTypes: readonly DatumType[] | undefined = undefined;

    /**
     * Datum(MainIndex)
     */
    private mainDatum: Datum | undefined = undefined;

    /**
     * DatumType(MainIndex)
     */
    private mainDatumType: DatumType | undefined = undefined;

    /**
     * Datumがない場合の表示値
     */
    private static NONE_DATUM_RENDER_VALUE = "***";

    /**
     *
     * @param node
     * @param nodeType
     * @param datums
     * @param datumTypes
     */
    constructor(
        node?: Node,
        nodeType?: NodeType,
        datums?: readonly Datum[],
        datumTypes?: readonly DatumType[],
    ) {
        const mainDatumType =
            datumTypes?.[nodeType?.mainDatumIndex ?? -1] ?? undefined;
        const mainDatum = datums?.find(
            (datum) => datum.typeId === mainDatumType?.id,
        );

        this.node = node;
        this.nodeType = nodeType;
        this.datumTypes = datumTypes;
        this.mainDatum = mainDatum;
        this.mainDatumType = mainDatumType;
    }

    /**
     * Link有効・無効判定処理
     * @returns
     */
    hasLink(): boolean {
        // 機器が無効な場合（以下をすべて満たす場合）
        // 1. mainIndexが設定されている
        // 2. mainIndexのdatumとdatumTypesがある
        // 3. mainIndexのlabelsが定義されている
        // 4. mainIndexのlabels[value]が範囲外
        // 特殊条件
        // ・mainIndexのdatumTypeのdatumがない場合は無効
        if (this.nodeType?.mainDatumIndex == null) {
            return true;
        }
        if (this.mainDatumType && this.mainDatum == null) {
            return false;
        }
        if (
            this.mainDatum == null ||
            this.mainDatumType == null ||
            this.mainDatum?.value == null
        ) {
            return true;
        }
        if (this.mainDatumType?.labels == null) {
            return true;
        }
        const value = Number(this.mainDatum.value);
        if (value >= 0 && this.mainDatumType.labels.length > value) {
            return true;
        }
        return false;
    }

    /**
     * 帯の表示・非表示判定処理
     * @returns
     */

    hasLabel(): boolean {
        const mainDatumIndex = this.nodeType?.mainDatumIndex;
        if (
            this.datumTypes &&
            this.datumTypes.some(
                (datumType) => datumType.nodeTypeId === this.nodeType?.id,
            ) &&
            mainDatumIndex != null &&
            mainDatumIndex !== -1
        ) {
            return true;
        }
        return false;
    }

    /**
     * 有効な状態項目か判定する処理
     * @param datum
     * @param datumType
     * @returns 有効な場合:true、無効な場合:false
     */
    private static isValidState(datum: Datum, datumType: DatumType): boolean {
        const value = datum.value == null ? undefined : Number(datum.value);
        if (
            value !== undefined &&
            Number.isInteger(value) &&
            value >= 0 &&
            datumType.labels &&
            datumType.labels.length > value
        ) {
            return true;
        }
        return false;
    }

    /**
     * 状態の場合のステータス取得処理
     * @param nodeType
     * @param datum
     * @param datumType
     * @returns
     */
    private static getModeStatus(
        nodeType: NodeType,
        datum: Datum,
        datumType: DatumType,
    ): NodeCardRule.Status {
        if (this.isValidState(datum, datumType)) {
            const value = Number(datum.value);
            // NOTE : 仕様
            // 0 = 緑
            // 1 = 赤
            // reversedLabelColorがtrueならば上記を反転
            // 上記以外：不明
            switch (nodeType.reversedLabelColor ? 1 - value : value) {
                case 0:
                    return NodeCardRule.Status.Green;
                case 1:
                    return NodeCardRule.Status.Red;
                default:
                    return NodeCardRule.Status.Unknown;
            }
        }
        return NodeCardRule.Status.Unknown;
    }

    /**
     * 数値の場合のステータス取得処理（None固定）
     * @returns
     */
    private static getModeValue(): NodeCardRule.Status {
        return NodeCardRule.Status.None;
    }

    /**
     * ステータス取得処理
     * @returns
     */
    getStatus(): NodeCardRule.Status {
        if (this.nodeType && this.mainDatum && this.mainDatumType) {
            if (this.mainDatumType.labels) {
                return NodeCardRule.getModeStatus(
                    this.nodeType,
                    this.mainDatum,
                    this.mainDatumType,
                );
            }
            return NodeCardRule.getModeValue();
        }
        return NodeCardRule.Status.Unknown;
    }

    /**
     * 状態の場合の表示文字列取得処理
     * @param datum
     * @param datumType
     * @param options
     * @returns 表示文字列
     */
    static getRenderStatus(
        datumType: DatumType,
        datum?: Datum,
        options?: NodeCardRule.RenderOptions,
    ): NodeCardRule.LabelInfo {
        const value = datum?.value == null ? undefined : Number(datum.value);
        let status;

        if (value == null) {
            status =
                options?.noneDatumCase?.status ??
                NodeCardRule.NONE_DATUM_RENDER_VALUE;
        } else if (
            Number.isInteger(value) &&
            value >= 0 &&
            datumType.labels &&
            datumType.labels.length > value
        ) {
            status = datumType.labels[value].message;
        } else {
            status = "状態更新中"; // Datum が labels の範囲外の場合
        }

        return {
            name: options?.valueOnly === true ? undefined : datumType.name,
            value: status,
            unit: undefined,
        };
    }

    /**
     * 数値の場合の表示文字列取得処理
     * @param datum
     * @param datumType
     * @param options
     * @returns 表示文字列
     */
    static getRenderValue(
        datumType: DatumType,
        datum?: Datum,
        options?: NodeCardRule.RenderOptions,
    ): NodeCardRule.LabelInfo {
        return {
            name: options?.valueOnly === true ? undefined : datumType.name,
            value:
                datum?.value == null
                    ? String(
                          options?.noneDatumCase?.value ??
                              NodeCardRule.NONE_DATUM_RENDER_VALUE,
                      )
                    : datumType.type === ValueType.String
                    ? datum.value
                    : Number(datum.value).toFixed(datumType.digits ?? 2),
            unit: datumType.unit,
        };
    }

    /**
     * Datum 表示文字列取得処理
     * @param datum
     * @param datumType
     * @param options
     * @returns 表示文字列
     */
    static getRenderDatum(
        datumType: DatumType,
        datum?: Datum,
        options?: NodeCardRule.RenderOptions,
    ): NodeCardRule.LabelInfo {
        switch (datumType.type) {
            case ValueType.Status:
                return NodeCardRule.getRenderStatus(datumType, datum, options);
            case ValueType.Number:
            case ValueType.Latitude:
            case ValueType.Longitude:
                return NodeCardRule.getRenderValue(datumType, datum, options);
            default:
                // 将来的に消す：type が定義されていない場合の処理
                if (datumType.labels) {
                    return NodeCardRule.getRenderStatus(
                        datumType,
                        datum,
                        options,
                    );
                }
                return NodeCardRule.getRenderValue(datumType, datum, options);
        }
    }

    /**
     * ラベル文字列取得処理
     * @returns ラベル文字列
     */
    getLabel(): NodeCardRule.LabelInfo {
        if (this.mainDatumType) {
            return NodeCardRule.getRenderDatum(
                this.mainDatumType,
                this.mainDatum,
                {
                    valueOnly:
                        this.mainDatumType.type === ValueType.Status ||
                        // 将来的に消す：type が定義されていない場合の処理
                        this.mainDatumType.labels != null,
                },
            );
        }
        return { value: "" };
    }

    /**
     * node を返す
     * @returns node
     */
    getNode(): Node | undefined {
        return this.node;
    }

    /**
     * nodeType を返す
     * @returns node
     */
    getNodeType(): NodeType | undefined {
        return this.nodeType;
    }

    /**
     * mainDatum を返す
     * @returns mainDatum
     */
    getMainDatum(): Datum | undefined {
        return this.mainDatum;
    }

    /**
     * mainDatumType を返す
     * @returns mainDatum
     */
    getMainDatumType(): DatumType | undefined {
        return this.mainDatumType;
    }

    /**
     * アイコンを取得する処理
     * 優先順：Node > NodeType 標準アイコン > NodeType 指定アイコン
     * @returns imagePath
     */
    getImagePath(): string | undefined {
        return (
            this.node?.imagePath ??
            images[this.nodeType?.imagePath ?? ""] ??
            this.nodeType?.imagePath ??
            undefined
        );
    }

    /**
     * S3 ファイルにアクセスするための URL を作成します。
     * @returns URL
     */
    async getBackgroundImagePath(): Promise<string | undefined> {
        const { node } = this;
        if (node == null || !CameraTypes.includes(node.typeId)) {
            return undefined;
        }

        try {
            return await Storage.get(`${node.id}/log/video.jpg`, {
                download: false,
            });
        } catch {
            return undefined;
        }
    }
}

/**
 *
 */
export namespace NodeCardRule {
    /**
     * NodeCardステータス
     */
    export enum Status {
        Green,
        Red,
        None,
        Unknown,
    }
    /**
     * 文字表示オプション
     */
    export type RenderOptions = {
        // 値のみを表示し項目名を表示しないフラグ
        valueOnly?: boolean;
        // Datum がない場合の表示を上書きする
        noneDatumCase?: {
            value?: number;
            status?: string;
        };
    };
    /**
     * 帯表示文字列項目
     */
    export type LabelInfo = {
        // 項目名
        name?: DatumType["name"];
        // 状態または値
        value: Datum["value"];
        // 単位
        unit?: DatumType["unit"];
    };
}

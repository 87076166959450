import machine01 from "./assets/machine01.svg";
import machine02 from "./assets/machine02.svg";
import machine03 from "./assets/machine03.svg";
import machine04 from "./assets/machine04.svg";
import machine05 from "./assets/machine05.svg";
import machine06 from "./assets/machine06.svg";
import machine07 from "./assets/machine07.svg";
import machine08 from "./assets/machine08.svg";
import machine09 from "./assets/machine09.svg";
import machine10 from "./assets/machine10.svg";
import machine11 from "./assets/machine11.svg";
import machine12 from "./assets/machine12.svg";
import machine13 from "./assets/machine13.svg";
import machine14 from "./assets/machine14.svg";
import machine15 from "./assets/machine15.svg";
import machine16 from "./assets/machine16.svg";
import machine17 from "./assets/machine17.svg";
import machine18 from "./assets/machine18.svg";
import machine19 from "./assets/machine19.svg";
import machine20 from "./assets/machine20.svg";
import machine21 from "./assets/machine21.svg";
import machine22 from "./assets/machine22.svg";
import machine23 from "./assets/machine23.svg";
import machine24 from "./assets/machine24.svg";
import machine25 from "./assets/machine25.svg";
import machine26 from "./assets/machine26.svg";
import machine27 from "./assets/machine27.svg";
import machine28 from "./assets/machine28.svg";
import machine29 from "./assets/machine29.svg";
import machine30 from "./assets/machine30.svg";
import machine31 from "./assets/machine31.svg";
import machine32 from "./assets/machine32.svg";
import machine33 from "./assets/machine33.svg";
import machine34 from "./assets/machine34.svg";
import machine35 from "./assets/machine35.svg";
import machine36 from "./assets/machine36.svg";
import machine37 from "./assets/machine37.svg";
import machine38 from "./assets/machine38.svg";
import machine39 from "./assets/machine39.svg";
import machine40 from "./assets/machine40.svg";
import machine41 from "./assets/machine41.svg";
import machine42 from "./assets/machine42.svg";
import machine43 from "./assets/machine43.svg";
import machine44 from "./assets/machine44.svg";
import noImage from "./assets/no-image.svg";

export { noImage };
export const images = Object.assign(
    Object.create(null) as Record<string, string | undefined>,
    {
        machine01,
        machine02,
        machine03,
        machine04,
        machine05,
        machine06,
        machine07,
        machine08,
        machine09,
        machine10,
        machine11,
        machine12,
        machine13,
        machine14,
        machine15,
        machine16,
        machine17,
        machine18,
        machine19,
        machine20,
        machine21,
        machine22,
        machine23,
        machine24,
        machine25,
        machine26,
        machine27,
        machine28,
        machine29,
        machine30,
        machine31,
        machine32,
        machine33,
        machine34,
        machine35,
        machine36,
        machine37,
        machine38,
        machine39,
        machine40,
        machine41,
        machine42,
        machine43,
        machine44,
        noImage,
    },
);
